import { mapActions, mapGetters } from 'vuex';

export default {
  computed: mapGetters({
    backdropIsActive: 'backdrop/isActive',
    backdropIsGloballyActive: 'backdrop/isGloballyActive',
    maintenanceDowntime: 'downtimes/maintenanceDowntime',
  }),
  head() {
    const htmlClasses = ['app'];
    if (this.backdropIsActive) {
      htmlClasses.push('app--has-backdrop');
    }
    if (this.backdropIsGloballyActive) {
      htmlClasses.push('app--has-global-backdrop');
    }
    return { htmlAttrs: { class: htmlClasses.join(' ') } };
  },
  async mounted() {
    await this.checkForDowntime();
    // Do NOT init cart on confirmation page - it will delete the session & the PDF
    if (
      !this.maintenanceDowntime &&
      this.$route?.name !== 'cryptoticket-invalid' &&
      this.$route?.name !== 'shop-bestaetigung'
    ) {
      await this.initCart();
    }
  },
  methods: {
    ...mapActions('downtimes', ['fetchDowntimes']),
    ...mapActions('cart', ['initCart']),
    async checkForDowntime() {
      // BE will response with no downtimes, when there is no cryptoticket!
      if (this.$route?.name === 'cryptoticket-invalid') return;
      await this.fetchDowntimes();
      if (this.$route?.name === 'maintenance') return;
      if (this.maintenanceDowntime) {
        await navigateTo('/maintenance');
      }
    },
  },
};
